.App {
  max-width: 600px;
  margin: auto;
  text-align: center;
  padding: 50px 30px;
}

.home-screen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 40px;
}

.language-wrapper {
  position: absolute;
  top: 20px;
  right: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.language-toggle {
  border: 1px solid #000;
  outline: none;
  box-shadow: 1px 1px 3px #0005;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 19px;
  height: 15px;
  cursor: pointer;
}
.language-toggle.active {
  outline: 2px solid #000;
}

.language-toggle--en {
  background-image: url('./img/english.svg');
}
.language-toggle--kr {
  background-image: url('./img/korean.svg');
}
.language-toggle--jp {
  background-image: url('./img/japanese.svg');
}

.title {
  text-transform: uppercase;
}
.home-screen .title {
  margin-bottom: 10px;
}

.title-letter {
  position: relative;
  display: inline-block;
  margin: 0 3px;
  min-width: 25px;
  line-height: 1;
}
.title-letter:after {
  position: absolute;
  content: "";
  bottom: -3px;
  width: 100%;
  left: 0;
  border-bottom: 2px solid #000;
}

.mode-title {
  margin-bottom: 10px;
}
.current-difficulty {
  margin-bottom: 10px;
}
.chances-remaining {
  line-height: 1;
}
.text--subdued {
  font-size: 0.8em;
  color: #555;
}

.mode-btn {
  width: 100%;
  max-width: 300px;
  margin: 0 auto 10px;
  padding: 20px 30px;
  text-transform: uppercase;
  font-family: 'Kdam Thmor Pro', sans-serif;
  letter-spacing: 2px;
  border: 2px solid currentColor;
  cursor: pointer;
}

.mode-btn:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.btn--daily {
  background-color: #98fb98;
  color: #000;
  font-size: 1.2rem;
}

.btn--practice {
  background-color: #6495ed;
  color: #000;
  font-size: 1.2rem;
}

.btn--instructions {
  background-color: #eaeaea;
  color: #000;
  font-size: 0.9rem;
}

.Word {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
  margin-top: 50px;
}

.Character {
  padding: 5px 5px 0px 5px;
  margin: 0 2px;
  text-transform: uppercase;
  font-size: 2rem;
  border-bottom: 2px solid #000;
  min-width: 35px;
  min-height: 60px;
  text-align: center;
  position: relative;
}

.Character span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
      -ms-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
}

.Graphic {
  width: calc(100% - 30px);
  max-width: 600px;
  margin: auto;
  border: 2px solid #000;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 30px;
  background-color: #FFF;
  text-align: center;
  z-index: 5;
  -webkit-box-shadow: 5px 8px 15px #000;
          box-shadow: 5px 8px 15px #000;
}

#result {
  display: block;
  font-size: 36px;
  margin-bottom: 30px;
}

#answer {
  display: block;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-size: 40px;
}

.toggle-btn {
  padding: 10px 20px;
  outline: none;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 2px solid #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #6495ed;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.reset-btn {
  padding: 10px 20px;
  outline: none;
  margin-top: 10px;
  border: 2px solid #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #98fb98;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.share-btn {
  padding: 10px 20px;
  outline: none;
  margin-top: 10px;
  border: 2px solid #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #98fb98;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.share-btn--native {
  display: block;
  padding: 10px 20px;
  outline: none;
  margin: 15px auto 0;
  border: 2px solid #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #6495ed;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.ps-ref {
  margin-top: 20px;
}

.ps-ref__link {
  font-size: 0.8rem;
  font-family: sans-serif;
}

@media only screen and (min-width: 769px) {
  .share-btn--native {
    display: none;
  }
}

.navigation {
  position: relative;
  text-align: left;
}

.home-btn {
  padding: 3px 5px;
  outline: none;
  margin-top: 10px;
  border: 2px solid #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #98fb98;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.new-word-timer {
  margin-top: 20px;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.keyboard-instructions {
  font-size: 12px;
  font-style: italic;
}

.Letters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.Letters--large {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.Letters--small {
  display: none;
}

.Letters__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 5px;
}

.Letter {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 35px;
  text-align: center;
}
.Letter.disabled {
  background-color: #ddd;
  color: #777;
  border: 1px solid #777;
  cursor: not-allowed;
}
.Letter:not(.disabled):hover {
  background-color: #000;
  color: navajowhite;
}

.Graphic.animate.animate__jackInTheBox {
  -webkit-animation: graphicJackInTheBox;
          animation: graphicJackInTheBox;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.animate.red.animate__flip {
  -webkit-animation: flipRed;
          animation: flipRed;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
}
.animate.green.animate__flip {
  -webkit-animation: flipGreen;
          animation: flipGreen;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
}

.animate.animate__fadeCharInUp {
  -webkit-animation: fadeCharInUp;
          animation: fadeCharInUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.notification {
  margin-top: 15px;
  font-size: 12px;
  color: rgb(5, 180, 5);
}
.notification.error {
  margin-top: 15px;
  font-size: 12px;
  color: rgb(200, 18, 18);
}

.manual-copy-text {
  display: inline-block;
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  margin: 15px auto 0;
}

.Modal {
  display: none;
  position: fixed;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 540px;
  width: calc(100% - 20px);
  height: calc(100vh - 20px);
  border: 2px solid #000;
  background-color: #fff;
  margin: 10px;
  padding: 40px 15px 20px;
  z-index: 4;
}

.Modal.open {
  display: block;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 8px;
  font-size: 16px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 5;
}

.modal__inner {
  height: 100%;
  overflow: auto;
  text-align: left;
}

.modal-title {
  text-decoration: underline;
  text-transform: uppercase;
}

.btn--definition {
  width: 80%;
  max-width: 300px;
  margin: 30px auto 0;
  padding: 10px 10px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  border: 2px solid currentColor;
  cursor: pointer;

  background-color: rgb(196, 162, 255);
  color: #000;
  font-size: 0.8rem;
}
.btn--definition[disabled] {
  display: none;
}

.btn--definition:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.definition-wrapper {
  margin-top: 30px;
  font-family: 'Arial', sans-serif;
  font-style: italic;
  font-size: 0.9rem;
}

.definition-wrapper--graphic {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: 'Arial', sans-serif;
  font-style: italic;
  font-size: 0.9rem;
}

.footer {
  margin-top: auto;
  padding: 5px 10px 20px;
  font-size: 12px;
  text-align: center;
}

.footer__copyright {
  font-family: sans-serif;
}

.game-information strong {
  text-decoration: underline;
}
.game-information p {
  line-height: 1.6;
}
.u-mb--small {
  margin-bottom: 1rem;
}

.u-mb--medium {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 768px) {
  .Graphic {
    padding: 25px;
  }

  #result {
    font-size: 28px;
    margin-bottom: 25px;
  }

  #answer {
    letter-spacing: 8px;
    font-size: 26px;
  }

  .Character {
    font-size: 1.4rem;
    min-width: 10px;
    max-width: 35px;
    min-height: 30px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  .Modal {
    max-width: unset;
    left: 0;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
            transform: translateX(0);
  }
}

@media only screen and (max-width: 480px) {
  .App {
    padding: 50px 10px;
  }

  .Graphic {
    padding: 25px 15px;
  }

  #result {
    font-size: 20px;
    margin-bottom: 20px;
  }

  #answer {
    letter-spacing: 8px;
    font-size: 22px;
    margin-bottom: 15px;
  }

  .Word {
    gap: 5px;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .Letters {
    gap: 15px;
    padding: 0;
    border: none;
  }

  .Letters--small {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .Letters--large {
    display: none;
  }

  .Letters__row {
    gap: 9px;
  }
}

@-webkit-keyframes graphicJackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg) translate(-50%, -50%);
    transform: scale(0.1) rotate(30deg) translate(-50%, -50%);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg) translate(-50%, -50%);
    transform: rotate(-10deg) translate(-50%, -50%);
  }

  70% {
    -webkit-transform: rotate(3deg) translate(-50%, -50%);
    transform: rotate(3deg) translate(-50%, -50%);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes graphicJackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg) translate(-50%, -50%);
    transform: scale(0.1) rotate(30deg) translate(-50%, -50%);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg) translate(-50%, -50%);
    transform: rotate(-10deg) translate(-50%, -50%);
  }

  70% {
    -webkit-transform: rotate(3deg) translate(-50%, -50%);
    transform: rotate(3deg) translate(-50%, -50%);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%);
  }
}

@-webkit-keyframes flipRed {
  0% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    background-color: #FFFFFF00;
    color: #000000;
  }
  40% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    background-color: #ff000077;
    color: #FFFFFF;
  }
  50% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #ff0000FF;
    color: #FFFFFF;
  }
  80% {
    -webkit-transform: perspective(200px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(200px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #ff000077;
    color: #FFFFFF;
  }
  100% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #FFFFFF00;
    color: #000000;
  }
}

@keyframes flipRed {
  0% {
    -webkit-transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    background-color: #FFFFFF00;
    color: #000000;
  }
  40% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    background-color: #ff000077;
    color: #FFFFFF;
  }
  50% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #ff0000FF;
    color: #FFFFFF;
  }
  80% {
    -webkit-transform: perspective(200px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(200px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #ff000077;
    color: #FFFFFF;
  }
  100% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #FFFFFF00;
    color: #000000;
  }
}

@-webkit-keyframes flipGreen {
  0% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    background-color: #FFFFFF00;
    color: #000000;
  }
  40% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    background-color: #00800077;
    color: #FFFFFF;
  }
  50% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #008000FF;
    color: #FFFFFF;
  }
  80% {
    -webkit-transform: perspective(200px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(200px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #00800077;
    color: #FFFFFF;
  }
  100% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #FFFFFF00;
    color: #000000;
  }
}

@keyframes flipGreen {
  0% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    background-color: #FFFFFF00;
    color: #000000;
  }
  40% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    background-color: #00800077;
    color: #FFFFFF;
  }
  50% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 80px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #008000FF;
    color: #FFFFFF;
  }
  80% {
    -webkit-transform: perspective(200px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(200px) scale3d(0.95, 0.95, 0.95) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #00800077;
    color: #FFFFFF;
  }
  100% {
    -webkit-transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    transform: perspective(200px) scale3d(1, 1, 1) translate3d(0, 0, 0) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    background-color: #FFFFFF00;
    color: #000000;
  }
}

@-webkit-keyframes fadeCharInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(0%) translateX(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translateY(-50%) translateX(-50%);
  }
}

@keyframes fadeCharInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(0%) translateX(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translateY(-50%) translateX(-50%);
  }
}
